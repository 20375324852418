import { useQueries, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AssetForTriaName, RootState } from "../package/rx.core";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useUserPrefs } from "./useFetchUserPrefs";
import { useTriaName } from "./useTriaName";

export const useFetchAssets = () => {
  // const queryClient = useQueryClient();
  const { getAssetsForATriaName, getAssetDetails } = useTriaUser();
  const { triaName } = useTriaName();
  const { data: userPrefs } = useUserPrefs();
  // const dispatch = useDispatch();
  const isUserInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );

  // Query for main assets
  const mainAssetsQuery = useQuery({
    queryKey: ["assets", triaName],
    queryFn: async () => {
      console.log("refetch assets : ");
      const assets = await getAssetsForATriaName(
        triaName || "",
        undefined,
        "amountDesc"
      );
      console.log("fetch assets : ", assets);
      return assets;
    },
    enabled: !!triaName && !isUserInactive,
    refetchInterval: 10000, // Try to refetch every 10 seconds
    refetchIntervalInBackground: true,
    retry: 3,
  });

  // Queries for custom tokens
  const customTokenQueries = useQueries({
    queries: (userPrefs?.customChains || []).flatMap((chain) =>
      (userPrefs?.customTokens || [])
        .filter((token) => token.chainId === chain.chainId)
        .map((token) => ({
          queryKey: ["customToken", chain.chainId, token.tokenAddress],
          queryFn: async () => {
            const accessToken = localStorage.getItem("tria.accessToken");
            const detail = await getAssetDetails({
              tokenAddress: token.tokenAddress,
              chain,
              triaName,
              type: "EOA",
              accessToken: accessToken || undefined,
            });
            return detail.data;
          },
          enabled:
            !!triaName &&
            !!userPrefs &&
            userPrefs.customChains.length > 0 &&
            !isUserInactive,
          refetchInterval: 10000, // Try to refetch every 10 seconds
          refetchIntervalInBackground: true,
          keepPreviousData: true,
        }))
    ),
  });

  // Combine main assets and custom tokens
  const allAssets = useMemo(() => {
    const mainAssets = mainAssetsQuery.data || [];
    const customAssets: AssetForTriaName[] = customTokenQueries
      .filter((query) => query.data)
      .map((query) => {
        const customAsset = query.data!;
        return {
          wallet: customAsset.wallet,
          name: customAsset.name,
          symbol: customAsset.symbol,
          logoUrl: customAsset.logoUrl,
          chainName: customAsset.chainName,
          chainLogo: customAsset.chainLogo,
          balanceInTokens: customAsset.balanceInTokens,
          balanceOfTokensInUnits: customAsset.balanceOfTokensInUnits,
          decimals: customAsset.decimals,
          balanceInUSD: customAsset.balanceInUSD,
          quoteRate: customAsset.quoteRate,
          tokenAddress: customAsset.tokenAddress,
          isNativeToken: customAsset.isNativeToken,
          isSpam: customAsset.isSpam,
          percentChangein24hr: customAsset.percentChangein24hr,
          isCustomToken: true,
        };
      });

    return [...mainAssets, ...customAssets];
  }, [mainAssetsQuery.data, customTokenQueries]);

  const isLoading =
    mainAssetsQuery.isLoading ||
    customTokenQueries.some((query) => query.isLoading);

  return {
    allAssets,
    isLoading,
    isError:
      mainAssetsQuery.isError ||
      customTokenQueries.some((query) => query.isError),
    error:
      mainAssetsQuery.error ||
      customTokenQueries.find((query) => query.error)?.error,
    refetch: () => {
      mainAssetsQuery.refetch();
      customTokenQueries.forEach((query) => query.refetch());
    },
  };
};
