import { isEvmChain } from "@tria-sdk/connect";
import { useMemo } from "react";
import { getWalletStoreLocalStorage } from "../package/rx.core/src/utils/getWalletStoreLocalStorage";
import { CustomChainDataType } from "../package/rx.core";

export function useUserAddresses({
  chainName,
  customChain,
}: {
  chainName: string | undefined;
  customChain?: CustomChainDataType;
}) {
  const { aaAddress, eoaAddress } = useMemo(() => {
    const storedData = getWalletStoreLocalStorage();
    if (storedData && customChain?.chainId) {
      const eoaAddress = storedData.addresses?.evm?.address || "";
      return { eoaAddress, aaAddress: "" };
    }
    if (storedData && chainName) {
      const eoaAddress = isEvmChain(chainName)
        ? storedData.addresses?.evm?.address || ""
        : storedData.addresses?.nonEvm.find((el) => el.chainName === chainName)
            ?.address || "";

      const aaAddress = isEvmChain(chainName)
        ? storedData.addresses?.aa?.address ?? ""
        : "";
      return {
        eoaAddress,
        aaAddress,
      };
    }
    return {
      eoaAddress: "",
      aaAddress: "",
    };
  }, [chainName]);

  return { aaAddress, eoaAddress };
}
