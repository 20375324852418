import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../index.css";
import { RootState, updateCurrentRoute } from "../../package/rx.core";
import { CryptoLite, Opentria } from "../../package/ui.common";

import { DisplayCostLite } from "../../package/ui.common/src/components/Cards/DisplayCostCard/DisplayCostCardLite";
import NftLite from "../../package/ui.common/src/layouts/Home/NftLite";
import { NavLite } from "../../package/ui.common/src/layouts/Navbar/NavBarLite";

function Lite() {
  const dispatch = useDispatch();

  dispatch(updateCurrentRoute(window.location.pathname));
  const [activeTab, setActiveTab] = useState<"crypto" | "nft">("crypto");
  const socialLoggedIn = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.socialLogin
  );

  const handleTabChange = (tab: "crypto" | "nft") => {
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-col w-screen h-screen bg-primaryColor dark:bg-primaryDarkColorLite font-montserrat">
      <NavLite />
      <div className="flex-grow flex flex-col">
        <DisplayCostLite />
        <div className="px-2 py-2 h-full">
          <div className="flex mb-4">
            <button
              className={`px-3 py-2 rounded-full cursor-pointer z-10 ${
                activeTab === "crypto"
                  ? " text-white font-semibold"
                  : " text-white/40 font-normal"
              }`}
              onClick={() => handleTabChange("crypto")}
            >
              Cryptos
            </button>
            <button
              className={`px-3 py-2 rounded-full cursor-pointer z-10 ${
                activeTab === "nft"
                  ? " text-white font-semibold"
                  : " text-white/40 font-normal"
              }`}
              onClick={() => handleTabChange("nft")}
            >
              NFTs
            </button>
          </div>
          <div className="flex-grow overflow-y-auto h-full">
            {activeTab === "crypto" ? <CryptoLite /> : <NftLite />}
          </div>
        </div>
        <div className="mb-2 flex items-center justify-center w-full">
          <Opentria url="https://t.me/tria_telegram_bot" />
        </div>
      </div>
    </div>
  );
}

export default Lite;
